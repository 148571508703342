import axios from 'axios';
//import { store } from 'stores/index'; // Import the Redux store



import store from 'stores/index';


// Get the API URL from the Redux store's initial state
const apiUrl = store.getState().config.apiUrl;

const api = axios.create({
  baseURL: apiUrl
});

// Add a request interceptor to include the token in all requests
api.interceptors.request.use(
  (config) => {
    const state = store.getState();
    const token = state.auth.accessToken; // Access the token from Redux
    
    console.log(token);

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

export default api;