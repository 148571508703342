import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
// import Home from './features/Home/HomePage';
// import Login from './features/Auth/Login';
// import Register from './features/Auth/Register';
// import Profile from './features/Profile/ProfilePage';
// import NotFound from './components/NotFound';
// layouts

import Admin from "layouts/Admin.js";
import Auth from "layouts/Auth.js";

// views without layouts

import Landing from "views/Landing.js";
import Profile from "views/Profile.js";
import App from "views/Index.js";
import ProtectedRoute from './components/Route/protectedRoute';

const AppRoutes = () => {
  const isAuthenticated = false; // Replace with actual authentication logic

  return (
    // <Router>
    //   <Routes>
    //     {/* Public Routes */}
    //     <Route path="admin" element={<Home />} />
    //     <Route path="/auth" element={<Login />} />
    //     <Route path="/register" element={<Register />} />

    //     {/* Protected Route Example */}
    //     <Route
    //       path="/profile"
    //       element={
    //         isAuthenticated ? <Profile /> : <Navigate to="/login" replace />
    //       }
    //     />

    //     {/* Catch-All Route for 404 Page */}
    //     <Route path="*" element={<NotFound />} />
    //   </Routes>
    // </Router>
    <BrowserRouter>
        <Switch>
        {/* add routes with layouts */}
           <ProtectedRoute path="/admin/dashboard" component={Admin} />
           {/* <Route path="/admin/dashboard" component={Admin} /> */}

           <Route path="/auth" component={Auth} />
        {/* add routes without layouts */}
            <Route path="/landing" exact component={Landing} />
            <Route path="/profile" exact component={Profile} />
            <Route path="/" exact component={App} />
        {/* add redirect for first page */}
            <Redirect from="*" to="/" />
        </Switch>
    </BrowserRouter>
  );
};

export default AppRoutes;