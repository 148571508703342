

import CardLineChart from "components/Cards/CardLineChart.js";
import CardBarChart from "components/Cards/CardBarChart.js";
import CardPageVisits from "components/Cards/CardPageVisits.js";
import CardSocialTraffic from "components/Cards/CardSocialTraffic.js";
import React, { useEffect } from "react";

import {useSelector } from 'react-redux';


import api from 'services/base/api';



const Dashboard = () => {

  const apiUrl = useSelector((state) => state.config.apiUrl);

  const fetchData = async () => {
    try {
      
      const response = await api.get(`${apiUrl}/administration/getcompanies`);
      console.log(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  
   // Fetch data when component mounts
   useEffect(() => {
    fetchData();
  }, []); // Empty dependency array to run only once on mount

  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full xl:w-8/12 mb-12 xl:mb-0 px-4">
          <CardLineChart />
        </div>
        <div className="w-full xl:w-4/12 px-4">
          <CardBarChart />
        </div>
      </div>
      <div className="flex flex-wrap mt-4">
        <div className="w-full xl:w-8/12 mb-12 xl:mb-0 px-4">
          <CardPageVisits />
        </div>
        <div className="w-full xl:w-4/12 px-4">
          <CardSocialTraffic />
        </div>
      </div>
    </>
  );
}

export default Dashboard;