import { combineReducers } from 'redux';
import configReducer from './configReducer';
// Import other reducers here
import authReducer from './auth/authSlice';


const rootReducer = combineReducers({
  config: configReducer,
  auth: authReducer,
});

export default rootReducer;
